/**
 * Bracket notation in the file name allows for dynamic routes.
 * These routes are generated with the getStaticPaths function.
 * Use of the spread operator in the file name extends the paths to catch all routes.
 * Ex: /services  /services/one  /services/two  &  /services/one/a/b/c
 * This allows pages to be added in Contentful without code updates.
 * @see {@link https://nextjs.org/docs/routing/dynamic-routes#catch-all-routes Dynamic Routes} Nextjs docs
 */

import NextError from 'next/error';
import Layout from '@layouts/Layout';
import { ThemeProvider } from 'theme-ui';
import { theme } from '#theme';
import { SpacingContext } from '@lib/hooks';
import { getPageData, isType, PageData } from '#utils';
import { PageSpacing } from '@lib/constants';
import type { NextPage, GetStaticPaths, GetStaticProps } from 'next';
import { ContentfulError } from '#types';
import type { ParsedUrlQuery } from 'querystring';
import { getPagePaths } from '@lib/utils/pagePaths';

// getStaticProps returns either a page or an error
type PageProps =
  | {
      page: PageData;
      error: undefined;
    }
  | {
      page: undefined;
      error: ContentfulError;
    };

interface Params extends ParsedUrlQuery {
  readonly slug?: Array<string>;
}

const Page: NextPage<PageProps> = ({ page, error }) => {
  if (error) {
    return (
      <NextError
        statusCode={500}
        title={`The state of content in Contenful is preventing this page
        from updating content. Check the content with the below Ids and see if
        published content is pointing to draft content:
        ${error.message}`}
      />
    );
  }

  const spacingObj = {
    pageSpacing: PageSpacing[page?.pageContent.pageSpacing || 'Loose'],
    heroSpacing: page?.pageContent.heroSpace ?? true,
    footerSpacing: page?.pageContent.footerSpace ?? true,
  };

  const {
    pageContent: {
      bodyContentCollection: { items: bodyContent = [] } = {},
      variant = 'Default',
      ...layoutPropsFromPageContent
    } = {},
    ...layoutPropsFromPage
  } = page;

  return (
    <ThemeProvider theme={theme}>
      <SpacingContext.Provider value={spacingObj}>
        <Layout
          variant={variant}
          {...layoutPropsFromPageContent}
          {...layoutPropsFromPage}
          bodyContent={bodyContent}
        />
      </SpacingContext.Provider>
    </ThemeProvider>
  );
};

/**
 * getStaticPaths is called at build time on server-side
 * and returns an array of all page slugs found in Contentful
 * this array is used to create the page routes.
 * @see {@link https://nextjs.org/docs/basic-features/data-fetching/get-static-paths getStaticPaths} Nextjs docs.
 */
export const getStaticPaths: GetStaticPaths = async () => {
  const paths = await getPagePaths();

  // { fallback: blocking } allows for server-render pages on-demand if the path doesn't exist
  return {
    paths: paths.filter((path) => path !== '/subscription-plans'),
    fallback: 'blocking',
  };
};

/**
 * getStaticProps is called at build time for each slug returned from getStaticPaths above.
 * Data specific to that page is fetched for page build.
 * @see {@link https://nextjs.org/docs/basic-features/data-fetching/get-static-props getStaticProps} Nextjs docs.
 */
export const getStaticProps: GetStaticProps<PageProps, Params> = async ({
  params,
}) => {
  const path = params?.slug?.join('/');

  try {
    const page = await getPageData(path ?? 'home');

    // check to see if page is valid
    // { notFound: true } serves the 404 page
    if (!page?.sys) {
      return { notFound: true };
    }

    return { props: { page } };
  } catch (error) {
    // catch any Contentful GraphQL error, and if in stage return
    // props object with error
    if (isType<ContentfulError>(error, 'message')) {
      if (
        process.env.NEXT_PUBLIC_URL?.includes('stage') ||
        process.env.NEXT_PUBLIC_URL?.includes('dev') ||
        typeof process.env.NEXT_PUBLIC_URL === 'undefined'
      ) {
        return { props: { error } };
      } else {
        throw new Error(error.message);
      }
    }

    /* eslint-disable-next-line no-console */
    console.error(error);
    throw new Error('Error in getStaticProps()');
  }
};

export default Page;
